import { Box, Typography, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import gitLemur from '../LemurGit.png';

import { UDSNavSearch } from '../../Root/uds-nav/UDSNavSearch';
import { t } from '../translations';
import { LangContext } from '@telus/frontend-common';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '390px',
    backgroundColor: '#F4F4F7',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: 'auto',
    },
  },
  leftRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',

    [theme.breakpoints.down('xs')]: {
      padding: '20px 20px',
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '32px',
    paddingRight: '0px',
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
  },
  title: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      whiteSpace: 'normal',
      fontSize: '32px',
      lineHeight: '40px',
    },
  },
  subtitle: {
    whiteSpace: 'nowrap',
    padding: '16px 0',
    [theme.breakpoints.down('xs')]: {
      whiteSpace: 'normal',
      fontSize: '14px',
    },
  },
  lemur: {
    background: `url(${gitLemur})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    width: '647px',
    height: '403px',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  searchBarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  },
  searchBar: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    borderRadius: '40px',
    fontSize: '16px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '16px',
      width: '300px',
    },
  },
}));

export const WelcomeHeader = () => {
  const classes = useStyles();
  const { lang } = useContext(LangContext);

  return (
    <Box className={classes.root}>
      <Box className={classes.leftRoot}>
        <Box className={classes.titleContainer}>
          <Typography className={classes.title} variant="h1" color="secondary">
            {t.welcome[lang]}
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
            {t.description[lang]}
          </Typography>
          <Box className={classes.searchBarContainer}>
            <UDSNavSearch showSearch setShowSearch={() => {}} />
          </Box>
        </Box>
      </Box>
      <Box className={classes.lemur} />
    </Box>
  );
};
