export const getNavItems = (t: any, lang: string) => {
  const navItems = [
    {
      id: '1',
      label: t.aiTools[lang],
      items: [
        {
          id: 'fuelIX',
          label: t.fuelIX[lang],
          href: 'https://app.fuelix.ai/',
        },
        {
          id: 'unicorn.ai',
          label: t.unicornAi[lang],
          href: '/gpt',
        },
        {
          id: 'spoc-copilot',
          label: t.spocCopilot[lang],
          href: '/gpt/copilots/spoc',
        },
      ],
    },
    {
      id: '2',
      label: t.learningAndDevelopment[lang],
      items: [
        {
          id: 'cioTecMentorship',
          label: t.cioTecMentorship[lang],
          href: '/cio-tec-mentorship',
        },
        {
          id: 'digitalCareerFramework',
          label: t.digitalCareerFramework[lang],
          href: 'https://sites.google.com/telus.com/digitalcareerframework/home',
        },
        {
          id: 'simplifyVideoLibrary',
          label: t.simplifyVideoLibrary[lang],
          href: '/video-library',
        },
      ],
    },
    {
      id: '3',
      label: t.monitoringAndPerformance[lang],
      items: [
        {
          id: 'autoCRQ',
          label: t.autoCRQ[lang],
          href: 'https://github.com/telus/auto-crq',
        },
        {
          id: 'dynatraceMonitor',
          label: t.dynatraceMonitor[lang],
          href: '/dynatrace-monitor',
        },
        {
          id: 'externalHealthcheck',
          label: t.externalHealthcheck[lang],
          href: '/external-health-check',
        },
        {
          id: 'ITSM Release Management',
          label: t.ITSMReleaseManagement[lang],
          href: 'https://sites.google.com/telus.com/rcop/home',
        },
        {
          id: 'securityMetrics',
          label: t.securityMetrics[lang],
          href: '/security-metrics',
        },
      ],
    },
    {
      id: '4',
      label: t.developerTools[lang],
      items: [
        {
          id: 'apiMarketplace',
          label: t.apiMarketplace[lang],
          href: '/api-marketplace',
        },
        {
          id: 'cloudManagement',
          label: t.cloudManagement[lang],
          href: '/cloud-management',
        },
        {
          id: 'softwareKits',
          label: t.softwareKits[lang],
          href: '/aac-template-landing',
        },
        {
          id: 'templates',
          label: t.templatesCardTitle[lang],
          href: '/create',
        },
      ],
    },
    {
      id: '5',
      label: t.documentationAndCatalogues[lang],
      items: [
        {
          id: 'productInventory',
          label: t.productInventory[lang],
          href: '/product-inventory',
        },
        {
          id: 'documentationCatalogue',
          label: t.documentationCatalogue[lang],
          href: '/docs',
        },
        {
          id: 'githubCatalogue',
          label: t.githubCatalogue[lang],
          href: '/catalog',
        },
      ],
    },
    {
      id: '6',
      label: t.communitiesOfPractice[lang],
      items: [
        {
          id: 'architectureCop',
          label: t.architectureCoP[lang],
          href: 'https://sites.google.com/telus.com/architecture-cop/home',
        },
        {
          id: 'telusSalesEngineeringExcellence',
          label: t.telusSalesEngineeringExcellence[lang],
          href: 'https://sites.google.com/telus.com/tsee/home',
        },
        {
          id: 'sre',
          label: t.sreCoP[lang],
          href: 'https://sites.google.com/telus.com/sre-practice-experience-portal'
        },
        {
          id: 'technical-program-managers',
          label: t.technicalProgramManagersCoP[lang],
          href: 'https://sites.google.com/telus.com/tpmcop/home',
        },
      ],
    },
    {
      id: '7',
      label: t.commandCentres[lang],
      items: [
        {
          id: 'identityCommandCentre',
          label: t.iccComponentCardTitle[lang],
          href: '/icc',
        },
        {
          id: 'trustAndSafety',
          label: t.tascComponentCardTitle[lang],
          href: '/tasc',
        },
      ],
    },
  ];
  return navItems;
};
