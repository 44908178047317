import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const spocPlugin = createPlugin({
  id: 'spoc',
  routes: {
    root: rootRouteRef,
  },
});

export const SpocPage = spocPlugin.provide(
  createRoutableExtension({
    name: 'SpocPage',
    component: () =>
      import('./components/Pages/HomePage/Home').then(m => m.SpocComponent),
    mountPoint: rootRouteRef,
  }),
);

export const SpocBot = spocPlugin.provide(
  createRoutableExtension({
    name: 'SpocBot',
    component: () =>
      import('./components/Pages/Chat/SpocPage').then(m => m.SpocPage),
    mountPoint: rootRouteRef,
  }),
);

export const SpocContainerPage = spocPlugin.provide(
  createRoutableExtension({
    name: 'SpocContainerPage',
    component: () =>
      import('./components/SpocContainerPage').then(m => m.SpocContainerPage),
    mountPoint: rootRouteRef,
  }),
);

export const HealthChecksPage = spocPlugin.provide(
  createRoutableExtension({
    name: 'HealthChecksPage',
    component: () =>
      import('./components/Dashborad/Settings/pages/HealthChecks/HealthChecksPage').then(m => m.HealthChecksPage),
    mountPoint: rootRouteRef,
  }),
);
