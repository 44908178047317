export const TELUS_PURPLE = "#4B286D";
export const TELUS_PURBLE_LIGHT = "#7C53A5";

export const TELUS_HERO_BG = "rgba(244, 244, 247, 1)";

export const TELUS_GREEN_DARK = "#1F5C09";
export const TELUS_GREEN = "#2b8000";

export const TELUS_GRAY_500 = "#8b8b8b";

export const TELUS_CARD_BACKGROUND = "#E3E6E8";
export const TELUS_BORDER_COLOR = "#E3E6E8";
export const TELUS_ANNOUNCEMENT_CARD_BG = "#F4F4F7";
export const TELUS_HP_CARD_BG2 = "#FAFAFA";

export const TELUS_ALERT_RED = "#C12335";
export const TELUS_ALERT_GREEN = "#2B8000";

export const TELUS_FONT_FAMILY = "HNforTELUS";

export const TELUS_ADMIN_CARD_BG = "#f9f9f9";
export const TELUS_ADMIN_CARD_BORDER_COLOR = "#e0e0e0";


export const GREY_SHUTTLE = '#676E73'
export const BG_MODAL = 'rgba(244, 244, 247, 0.97)'

export const TELUS_FONT_BLACK = 'rgba(0, 0, 0, 0.87)'