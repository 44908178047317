/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import {
  DiscoveryApi,
  FetchApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import {
  apiClient,
  CreateProjectRequest,
  getCloudProjectByIdRequest,
  getCloudProjectRequest,
  PatchProjectRequest,
} from './apiClient';

import { ProjectDetail } from '../models';

export class DseOnbaordingClient implements apiClient {
  constructor(
    private discovery: DiscoveryApi,
    private fetchApi: FetchApi,
    private identityApiRef: IdentityApi,
  ) {}

  private async route(path: string, init?: RequestInit) {
    const fetchInit = init ?? {};

    if (path.includes('auth')) {
      const userProfile = (await this.identityApiRef.getProfileInfo()).email;
      const userIdentity = await this.identityApiRef.getBackstageIdentity();
      const authProviderId = JSON.parse(JSON.stringify(this.identityApiRef))
        .target.config.identityApi.authApi.sessionManager.connector.provider.id;
      const repsonseObject = { userIdentity, authProviderId, userProfile };
      return repsonseObject;
    }

    fetchInit.headers = {
      ...fetchInit.headers,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
    };

    try {
      const baseUrl = await this.discovery.getBaseUrl('dse-analytics-frontend');
      const response = await this.fetchApi.fetch(
        `${baseUrl}${path}`,
        fetchInit,
      );

      return await response.json();
    } catch (e: any) {
      throw new Error(`Was not able to get route due to : ${e.message}`);
    }
  }
  async authenicationInfo(): Promise<any> {
    try {
      const response = await this.route(`/auth`);
      return response;
    } catch (e) {
      throw new Error(`Could not project alias validation die : ${e}`);
    }
  }

  async getProjectList(request: getCloudProjectRequest): Promise<any> {
    try {
      const getProject = await this.route(
        `/project-list/type/${request.type}?limit=${
          request.limit || 1000
        }&offset=${request.offset || 1}${
          request.status ? `&status=${request.status}` : ''
        }${
          request.projectOwner ? `&projectOwner=${request.projectOwner}` : ''
        }${request.projectName ? `&projectName=${request.projectName}` : ''}`,
      );
      const currentUrl = window.location.href;
      let filteredProjects = getProject;

      if (currentUrl.includes('workbench')) {
        filteredProjects = getProject.filter(
          (project: any) => project.bundleId === 'ai-workbench',
        );
      } else if (currentUrl.includes('bi-layer')) {
        filteredProjects = getProject.filter(
          (project: any) => project.bundleId === 'bi-serve',
        );
      }
      return filteredProjects;
    } catch (e) {
      throw new Error('Could not retrieve the DSE projects');
    }
  }
  async getProjectDetails(
    request: getCloudProjectByIdRequest,
  ): Promise<ProjectDetail> {
    try {
      const getProjectById = await this.route(`/project-detail/${request}`);
      return getProjectById;
    } catch (e) {
      throw new Error('Could not retrieve the cloud project by id');
    }
  }
  async createProject(request: CreateProjectRequest): Promise<ProjectDetail> {
    try {
      const payload = { ...request.body, projectType: request.type };
      const requestInit = {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const response = await this.route(`/create-project`, requestInit);

      return response;
    } catch (error) {
      console.error('dse_error', error);
      throw new Error('Could not create the project');
    }
  }
  async patchProject(request: PatchProjectRequest): Promise<ProjectDetail> {
    try {
      const requestInit = {
        method: 'PATCH',
        body: JSON.stringify(request.body),
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const response = await this.route(
        `/patch-project/${request.id}`,
        requestInit,
      );
      return response;
    } catch (error) {
      console.error('dse_errorr', error);
      throw new Error('Could not patch the project');
    }
  }
  async getTeamMemberData(email: string): Promise<any> {
    try {
      const response = await this.route(`/team-member/${email}`);
      return response;
    } catch (e: any) {
      throw new Error(
        `Could not get team member data for ${email} due to: ${e.message}`,
      );
    }
  }
  async getTeamMemberHierarchy(tid: string): Promise<any> {
    try {
      const response = await this.route(`/team-member-hierarchy/${tid}`);
      return response;
    } catch (e: any) {
      throw new Error(
        `Could not get team member hierarchy for ${tid} due to: ${e.message}`,
      );
    }
  }
  async getEmailbyGithubID(githubID: string): Promise<any> {
    try {
      const response = await this.route(`/github-lookup/github/${githubID}`);
      return response;
    } catch (e: any) {
      throw new Error(
        `Could not get email for github ID ${githubID} due to: ${e.message}`,
      );
    }
  }
  async getGithubIDbyEmail(email: string): Promise<any> {
    try {
      const response = await this.route(`/github-lookup/email/${email}`);
      return response;
    } catch (e: any) {
      throw new Error(
        `Could not get github ID for ${email} due to: ${e.message}`,
      );
    }
  }
  async validateProjectAlias(alias: string): Promise<any> {
    try {
      const response = await this.route(`/validate-project-alias/${alias}`);
      return response;
    } catch (e: any) {
      throw new Error(`Could not project alias validation die : ${e.message}`);
    }
  }
  async isDseAdmin(email: string): Promise<any> {
    try {
      const response = await this.route(`/is-dse-admin/${email}`);
      return response;
    } catch (e: any) {
      throw new Error(`Could not get DSE admin status due to: ${e.message}`);
    }
  }
}
