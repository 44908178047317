import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { ProviderCard } from './ProviderCard';
import {
  ProviderComponent,
  ProviderLoader,
  SignInProvider,
  SignInProviderConfig,
} from './types';
import { useApi, errorApiRef } from '@backstage/core-plugin-api';
import { GridItem } from './styles';
import { UserIdentity } from '@backstage/core-components';
import { useSearchParams } from 'react-router-dom';
import { t } from '../translations';

const Component: ProviderComponent = ({ config, onSignInSuccess }) => {
  const { apiRef, title, message } = config as SignInProviderConfig;
  const authApi = useApi(apiRef);
  const errorApi = useApi(errorApiRef);

  const [lang, setLang] = useState('');
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.get('lang')) {
      setLang('en');
    }
    if (searchParams.get('lang') === 'fr') {
      setLang('fr');
    }
    if (searchParams.get('lang') === 'en') {
      setLang('en');
    }
  }, [searchParams]);
  const handleLogin = async () => {
    try {
      const identityResponse = await authApi.getBackstageIdentity({
        instantPopup: true,
      });
      if (!identityResponse) {
        throw new Error(
          `The ${title} provider is not configured to support sign-in`,
        );
      }

      const profile = await authApi.getProfile();

      onSignInSuccess(
        UserIdentity.create({
          identity: identityResponse.identity,
          profile,
          authApi,
        }),
      );
    } catch (error) {
      errorApi.post(new Error(`Login failed, ${error}`));
    }
  };

  return (
    <GridItem>
      <ProviderCard
        variant="gridItem"
        title={title}
        actions={
          <Button
            style={{ width: '100%', textTransform: 'none' }}
            color="primary"
            variant="contained"
            onClick={handleLogin}
          >
            {t.signIn[lang]}
          </Button>
        }
      >
        <Typography variant="body1">{message}</Typography>
      </ProviderCard>
    </GridItem>
  );
};

const loader: ProviderLoader = async (apis, apiRef) => {
  const authApi = apis.get(apiRef)!;

  const identityResponse = await authApi.getBackstageIdentity({
    optional: true,
  });

  if (!identityResponse) {
    return undefined;
  }

  const profile = await authApi.getProfile();

  return UserIdentity.create({
    identity: identityResponse.identity,
    profile,
    authApi,
  });
};

export const commonProvider: SignInProvider = { Component, loader };
