import { createPlugin } from '@backstage/core-plugin-api';
import { createSearchResultListItemExtension } from '@backstage/plugin-search-react';
import { rootRouteRef } from './routes';
import { TurbopufferSearchResultListItemProps } from './components/TurbopufferSearch';

export const searchFrontendPlugin = createPlugin({
  id: 'search-frontend',
  routes: {
    root: rootRouteRef,
  }
});

export const TurbopufferSearchResultListItem: (
  props: TurbopufferSearchResultListItemProps,
) => JSX.Element | null = searchFrontendPlugin.provide(
  createSearchResultListItemExtension({
    name: 'TurbopufferSearchResultListItem',
    component: () =>
      import('./components/TurbopufferSearch').then(
        m => m.TurbopufferSearchResultListItem,
      ),
    predicate: result => {
      const allowedTypes = [
        'turbopuffer-collator-factory',
        // 'turbopuffer-collator',
        // 'turbopuffer-factory'
      ];
      return allowedTypes.includes(result.type);
    },
  }),
);
