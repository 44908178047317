import { useAsync } from 'react-use';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';

export default function useAuth() {
  const identityApi = useApi(identityApiRef);
  const auth = useAsync(async () => {
    const jwt = await identityApi.getCredentials();

    if (!jwt) {
      throw new Error('Could not get Backstage token.');
    }

    return { jwt };
  }, [identityApi]);
  return { auth };
}
