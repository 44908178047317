import React, { useState, useEffect, useMemo } from 'react';
import { Modal, TextInput, List, Link } from '@telus-uds/components-web';
import { useHotkeys } from 'react-hotkeys-hook';
import { NavItem, NavTab } from '../types';

interface UDSNavCommandSearchProps {
  navItems: NavTab[];
  isOpen: boolean;
  onClose: () => void;
}

export const UDSNavCommandSearch: React.FC<UDSNavCommandSearchProps> = ({
  navItems,
  isOpen,
  onClose,
}) => {
  const [query, setQuery] = useState('');
  const [filteredItems, setFilteredItems] = useState<NavItem[]>([]);

  // Extract only items with href (ignore tabs)
  const allItems = useMemo(
    () => navItems.flatMap(tab => tab.items).filter(item => item.href),
    [navItems],
  );

  useEffect(() => {
    if (query.trim() === '') {
      setFilteredItems([]);
    } else {
      setFilteredItems(
        allItems.filter(item =>
          item.label.toLowerCase().includes(query.toLowerCase()),
        ),
      );
    }
  }, [query, allItems]);

  useHotkeys('ctrl+k, cmd+k', event => {
    event.preventDefault();
    onClose();
  });

  useHotkeys('escape', () => {
    setQuery('');
    onClose();
  });

  const handleModalClose = () => {
    setQuery('');
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleModalClose}
      heading="Search"
      maxWidth
      tokens={{
        backdropColor: 'black',
        backdropOpacity: 0.5,
      }}
    >
      <TextInput
        value={query}
        onChange={(value: string) => setQuery(value)}
        placeholder="Search Tabs"
      />
      <List>
        {filteredItems.map(item => (
          <List.Item key={item.id}>
            <Link href={item.href}>{item.label}</Link>
          </List.Item>
        ))}
      </List>
    </Modal>
  );
};
