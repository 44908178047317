import React, { Suspense }  from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Skeleton } from '@material-ui/lab';
import { Page, Content } from '@backstage/core-components';
import { init, loadRemote } from '@module-federation/enhanced/runtime';
import useAuth from '../hooks/useAuth';
import useStyles from '../hooks/useStyles';
import { mfeConfig } from './config';

export const generateMfeComponent = (componentName: string) => {
  const componentConfig = mfeConfig[componentName];

  init(componentConfig.config);

  const LazyLoadedComponent = React.lazy(
    () => loadRemote(componentConfig.remote) as any,
  );

  const MfeComponent = () => {
    const { loadingSymbolStyles } = useStyles();
    const { auth } = useAuth();
    const { value, loading } = auth;

    if (loading) {
      return (
        <div className={loadingSymbolStyles}>
          <CircularProgress />
        </div>
      );
    }

    if (auth.error) {
      return <div>Error: {auth.error.message}</div>;
    }

    return (
      <Page themeId="tool">
        <Content>
          <Suspense fallback={<Skeleton variant="rect" height={400} />}>
            <LazyLoadedComponent jwt={value?.jwt.token} />
          </Suspense>
        </Content>
      </Page>
    );
  }

  return MfeComponent;
};
