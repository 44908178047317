import {
  NavItem,
  NavItemWithOptionalHref,
  NavTab,
  CollapseItemsProps,
} from '../types';

/**
 * Collapses navigation tabs into a single dropdown menu while preserving nested structure
 *
 * @returns {NavTab[]} A single-item array containing the collapsed menu
 */
export const collapseItems = ({
  items,
  selectedId,
}: CollapseItemsProps): NavTab[] => {
  if (!items?.length) return items;

  const isSelected = ({ label, id }: NavItemWithOptionalHref) =>
    selectedId === id || selectedId === label;

  let menuLabel = 'Menu';
  items.some(tab => {
    if (isSelected(tab)) {
      menuLabel = tab.label;
      return true;
    }

    const nestedMatch = tab.items?.find(isSelected);
    if (nestedMatch) {
      menuLabel = nestedMatch.label;
      return true;
    }

    return false;
  });

  const collapsedItems: NavItem[] = items.map(tab => ({
    id: tab.id,
    label: tab.label,
    href: tab.items && tab.items.length > 0 ? tab.items[0].href : '#',
    'data-category': 'true',
    items: tab.items,
  })) as NavItem[];

  return [
    {
      id: 'collapsed-item-root',
      label: menuLabel,
      items: collapsedItems,
    },
  ];
};
