import {
  ConfigApi,
  createApiRef,
  DiscoveryApi,
  IdentityApi,
  ProfileInfo,
} from '@backstage/core-plugin-api';
import { FrontendClient } from '@telus/frontend-common';

const CACHE_DURATION_MS = 8 * 60 * 60 * 1000; // 8 hours
const CACHE_PORTFOLIOS_KEY = '@product-inventory-portfolios';
const CACHE_ELEMENTS_DATETIME_KEY = '@product-inventory-elements-datetime';

export type ContentId = 'inventory-description';

export interface ProductInventoryServiceApi {
  getProductGroups: () => Promise<ProductGroup[]>;
  getProductChangeLog: (
    productGroupId: string,
  ) => Promise<ProductGroupChangeLog[]>;
  getPortfolios: () => Promise<string[]>;
  addProductGroup: (product: ProductGroup) => Promise<ProductGroup>;
  updateProductGroup: (product: ProductGroup) => Promise<ProductGroup>;
  getProfileInfo: () => Promise<ProfileInfo>;
  getContent: (contentId: ContentId) => Promise<string>;
  addProduct: (productGroupId: string, product: Product) => Promise<Product>;
  updateProduct: (productGroupId: string, product: Product) => Promise<Product>;
  deleteProduct: (productGroupId: string, productId: string) => Promise<void>;
  serchApplicationByName(searchText: string): Promise<Application[]>;
  teamMemberLookup(searchText: string): Promise<TeamMember[]>;
}

export const productInventoryApiRef = createApiRef<ProductInventoryServiceApi>({
  id: 'plugin.product-inventory.service',
});

export type Options = {
  identityApi: IdentityApi;
  discoveryApi: DiscoveryApi;
  configApi: ConfigApi;
};

export class ProductInventoryServiceApiClient
  extends FrontendClient
  implements ProductInventoryServiceApi
{
  constructor(options: Options) {
    super({
      discoveryApi: options.discoveryApi,
      identityApi: options.identityApi,
      defaultPlugin: 'product-inventory',
    });
  }

  async addProduct(productGroupId: string, product: Product): Promise<Product> {
    const profile = await this.getProfileInfo();
    product.lastModifiedBy = profile.email ?? profile.displayName ?? 'unknown';
    const resp: Product = await this.postRequired(
      `/productGroup/${productGroupId}/products`,
      product,
    );
    return resp;
  }

  async updateProduct(
    productGroupId: string,
    product: Product,
  ): Promise<Product> {
    const profile = await this.getProfileInfo();
    product.lastModifiedBy = profile.email ?? profile.displayName ?? 'unknown';
    const resp: Product = await this.putRequired(
      `/productGroup/${productGroupId}/products`,
      product,
    );
    return resp;
  }

  async deleteProduct(
    productGroupId: string,
    productId: string,
  ): Promise<void> {
    const profile = await this.getProfileInfo();
    const lastModifiedBy = profile.email ?? profile.displayName ?? 'unknown';
    await this.deleteRequired(
      `/productGroup/${productGroupId}/products/${productId}?lastModifiedBy=${lastModifiedBy}`,
    );
  }

  async getProfileInfo(): Promise<ProfileInfo> {
    const profileInfo = await this.identityApi.getProfileInfo();
    return profileInfo;
  }

  async getPortfolios(): Promise<string[]> {
    const cachedData = localStorage.getItem(CACHE_PORTFOLIOS_KEY);
    const cachedTime = localStorage.getItem(CACHE_ELEMENTS_DATETIME_KEY);

    if (
      cachedData &&
      cachedTime &&
      new Date().getTime() - Number(cachedTime) < CACHE_DURATION_MS
    ) {
      // Data is less than 8 hours old, use the cached version
      return Promise.resolve(JSON.parse(cachedData));
    }
    // Data is old or not existent, fetch again
    await this.getProductGroups();
    const newCachedData = localStorage.getItem(CACHE_PORTFOLIOS_KEY);
    return Promise.resolve(JSON.parse(newCachedData ? newCachedData : '[]'));
  }

  async getProductGroups(): Promise<ProductGroup[]> {
    const resp: ProductGroup[] = await this.getRequired('/productGroup');
    let portfoliosSet = new Set<string>();

    resp.forEach((product: ProductGroup) => {
      if (product.portfolio) {
        portfoliosSet.add(product.portfolio ?? '');
      }
    });
    portfoliosSet = new Set(
      Array.from(portfoliosSet).sort((a, b) => a.localeCompare(b)),
    );
    this.setPortfolios(Array.from(portfoliosSet));
    localStorage.setItem(
      CACHE_ELEMENTS_DATETIME_KEY,
      String(new Date().getTime()),
    );
    return resp;
  }

  async getProductChangeLog(id: string): Promise<ProductGroupChangeLog[]> {
    const resp: ProductGroupChangeLog[] = await this.getRequired(
      `/productGroup/${id}/changeLog`,
    );
    return resp;
  }

  private setPortfolios(portfolios: string[]): void {
    localStorage.setItem(CACHE_PORTFOLIOS_KEY, JSON.stringify(portfolios));
  }

  async addProductGroup(product: ProductGroup): Promise<ProductGroup> {
    const profile = this.getProfileInfo();
    product.lastModifiedBy =
      (await profile).email ?? (await profile).displayName ?? 'unknown';
    const resp: ProductGroup = await this.postRequired(
      '/productGroup',
      product,
    );
    return resp;
  }

  async updateProductGroup(product: ProductGroup): Promise<ProductGroup> {
    const profile = this.getProfileInfo();
    product.lastModifiedBy =
      (await profile).email ?? (await profile).displayName ?? 'unknown';
    const resp: ProductGroup = await this.putRequired('/productGroup', product);
    return resp;
  }

  async getContent(contentId: ContentId): Promise<string> {
    return await this.getRequired(`/contents/${contentId}`);
  }

  async serchApplicationByName(searchText: string): Promise<Application[]> {
    const resp: Application[] = await this.getRequired(
      `/application?searchText=${encodeURIComponent(searchText)}`,
    );
    return resp;
  }

  async teamMemberLookup(searchText: string): Promise<TeamMember[]> {
    const resp: TeamMember[] = await this.getRequired(
      `/teamMemberLookup?searchText=${encodeURIComponent(searchText)}`,
    );
    return resp;
  }
}
