import React, { forwardRef, useState } from 'react';
import { NavigationBar, Typography, Button } from '@telus-uds/components-web';
import { useNavigate } from 'react-router-dom';
import { NavTab } from '../types';
import * as alliumPalette from '@telus-uds/palette-allium/build/web/palette';

const { color } = alliumPalette;

interface CustomNavigationBarProps {
  items: NavTab[];
  selectedId?: string;
  onChange?: (id: string) => void;
  className?: string;
}

export const CustomNavigationBar: React.FC<CustomNavigationBarProps> = ({
  items,
  className,
}) => {
  const navigate = useNavigate();

  const CustomLinkRouter = forwardRef<HTMLButtonElement, any>(
    ({ href, children, ...props }, ref) => {
      const [isHovered, setIsHovered] = useState(false);

      const handleCustomClick = (e: any) => {
        e.preventDefault();

        // For top-level dropdown items, just call the original onClick
        if (!href) {
          if (props.onClick) props.onClick(e);
          return;
        }

        // For sub-items with href, implement custom nav logic
        if (href.startsWith('http')) {
          window.open(href, '_blank', 'noopener,noreferrer');
        } else {
          navigate(href);
        }

        // Still call the original onClick to maintain any other behavior
        if (props.onClick) props.onClick(e);
      };

      return (
        <Button
          ref={ref}
          onPress={handleCustomClick}
          variant={{ size: 'small', width: 'full' }}
          tokens={{
            borderRadius: '0px',
            borderColor: 'transparent',
            backgroundColor: isHovered ? color.greyAthens : 'white',
            textAlign: 'left',
            paddingLeft: '16px',
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          className="custom-nav-button"
        >
          <Typography tokens={{ textAlign: 'left', width: '100%' }}>
            {props.label}
          </Typography>
        </Button>
      );
    },
  );

  CustomLinkRouter.displayName = 'CustomLinkRouter';

  return (
    <NavigationBar
      className={className}
      items={items}
      selectedId="" // used for styling
      LinkRouter={CustomLinkRouter}
    />
  );
};
