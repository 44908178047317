import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() => createStyles({
  loadingSymbolStyles: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}));
