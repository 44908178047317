import { createApiRef } from '@backstage/core-plugin-api';
import {
  CloudProjectCreate,
  CloudProjectPatch,
  ProjectDetail,
} from '../models';

export interface getCloudProjectRequest {
  type: string;
  projectId?: string;
  projectAlias?: string;
  projectName?: string;
  fields?: string;
  status?: string;
  projectOrg?: string;
  offset?: number;
  limit?: number;
  projectOwner?: string | null;
}
export interface getCloudProjectByIdRequest {
  type: string;
  id: string;
  fields?: string;
}
export interface CreateProjectRequest {
  type: string;
  body: CloudProjectCreate;
}
export interface PatchProjectRequest {
  type: string;
  id: string;
  body: CloudProjectPatch;
}
export type apiClient = {
  getProjectList(request: getCloudProjectRequest): Promise<any>;
  getProjectDetails(
    request: getCloudProjectByIdRequest,
  ): Promise<ProjectDetail>;
  createProject(request: CreateProjectRequest): Promise<ProjectDetail>;
  patchProject(request: PatchProjectRequest): Promise<ProjectDetail>;
  getTeamMemberData(email: string): Promise<any>;
  getTeamMemberHierarchy(tid: string): Promise<any>;
  getEmailbyGithubID(githubID: string): Promise<any>;
  getGithubIDbyEmail(email: string): Promise<any>;
  validateProjectAlias(alias: string): Promise<any>;
  isDseAdmin(email: string): Promise<any>;
};

export const dseOnbaordApiRef = createApiRef<apiClient>({
  id: 'plugin.dse-onboard.service',
});
