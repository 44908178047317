import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const adminPanelPlugin = createPlugin({
  id: 'admin-panel',
  routes: {
    root: rootRouteRef,
  },
});

export const AdminPage = adminPanelPlugin.provide(
  createRoutableExtension({
    name: 'AdminPage',
    component: () =>
      import('./components/AdminPanel').then(m => m.AdminPanel),
    mountPoint: rootRouteRef,
  }),
);

export const AdminPanelPage = adminPanelPlugin.provide(
  createRoutableExtension({
    name: 'AdminPanelPage',
    component: () =>
      import('./components/AdminPanel').then(m => m.AdminPanelComponent),
    mountPoint: rootRouteRef,
  }),
);
