import React from 'react';
import { makeStyles, Theme, Grid, Paper } from '@material-ui/core';
import { TurbopufferSearchResultListItem } from '@telus/backstage-plugin-search-frontend';
import { NavSearch } from '../Root/old-nav/NavSearch';
import { SearchType } from '@backstage/plugin-search';
import {
  SearchFilter,
  SearchResult,
  SearchPagination,
} from '@backstage/plugin-search-react';
import {
  Content,
  Header,
  Page,
} from '@backstage/core-components';
import BoltIcon from '@mui/icons-material/Bolt';

const useStyles = makeStyles((theme: Theme) => ({
  bar: {
    padding: theme.spacing(1, 0),
  },
  searchBar: {
    position: 'relative',
    zIndex: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    borderRadius: '40px',
    fontSize: '16px',
    [theme.breakpoints.down('md')]: {
      width: '400px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '16px',
      width: '340px',
    },
  },
  filters: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  filter: {
    '& + &': {
      marginTop: theme.spacing(2.5),
    },
  },
}));

const SearchPage = () => {
  const classes = useStyles();

  return (
    <Page themeId="home">
      <Header title="Search" />
      <Content>
        <Grid container direction="row">
          <Grid item xs={12}>
            <div style={{ display: 'flex' }}>
              <NavSearch className={classes.searchBar} onClear={() => {}} />
            </div>
          </Grid>
          <Grid item xs={3}>
            <SearchType.Accordion
              name="Result Type"
              types={[
                {
                  value: 'turbopuffer-collator-factory',
                  name: 'Turbopuffer',
                  icon: <BoltIcon />,
                },
              ]}
            />
            <Paper className={classes.filters}>
              <SearchFilter.Select
                className={classes.filter}
                label="Kind"
                name="kind"
                values={['Component', 'Template']}
              />
              <SearchFilter.Checkbox
                className={classes.filter}
                label="Lifecycle"
                name="lifecycle"
                values={['experimental', 'production']}
              />
            </Paper>
          </Grid>
          <Grid item xs={9}>
            <SearchPagination />
            <SearchResult>
              <TurbopufferSearchResultListItem icon={<BoltIcon />} />
            </SearchResult>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};

export const searchPage = <SearchPage />;
