/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { PropsWithChildren, useContext } from 'react';
import { Sidebar } from '@backstage/core-components';
import { UDSNav } from './uds-nav/UDSNav';
import { LangContext } from '@telus/frontend-common';
import { t } from './uds-nav/translations';
import { getNavItems } from './uds-nav/UDSNavItems';

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const { lang } = useContext(LangContext);
  const navItems = getNavItems(t, lang);

  return (
    <div 
    style={{
      maxHeight: '100vh',
      display: 'flex',
      flexDirection: 'column'
    }}
    >
      <UDSNav navItems={navItems} />
      <Sidebar />
      {children}
    </div>
  );
};
