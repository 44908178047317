import React from 'react';
import ReactDOM from 'react-dom';

const urlMapping: any = {
  localhost: 'https://assets.wcpreview.telus.com/882035351/starter-kit-forms-mfe/v1/mf2/mf-manifest.json', // 'http://localhost:4321/mf-manifest.json',
  'simplify.developers.telus.digital':
    'https://assets.wcpreview.telus.com/882035351/starter-kit-forms-mfe/v1/mf2/mf-manifest.json',
  'simplify.staging.developers.telus.digital':
    'https://assets.wcstage.telus.com/882035351/starter-kit-forms-mfe/v1/mf2/mf-manifest.json',
  'simplify.telus.com':
    'https://assets.telus.com/882035351/starter-kit-forms-mfe/v1/mf2/mf-manifest.json',
};

const wask = {
  remote: 'starter_kit_forms_mfe/wask',
  config: {
    name: 'app_creation_page',
    remotes: [
      {
        name: 'starter_kit_forms_mfe',
        entry: urlMapping[window.location.hostname]
      },
    ],
    shared: {
      react: {
        version: '18.2.0',
        lib: () => React,
        shareConfig: {
          singleton: true,
          requiredVersion: '^18.2.0',
        },
      },
      'react-dom': {
        version: '18.2.0',
        lib: () => ReactDOM,
        shareConfig: {
          singleton: true,
          requiredVersion: '^18.2.0',
        },
      },
    },
  }
};

export default wask;
