import React, { useState, useRef, useEffect } from 'react';
import {
  Search as SearchIcon,
  Close,
} from '@telus-uds/palette-allium/build/web/icons';
import { IconButton, Search } from '@telus-uds/components-web';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    position: 'relative',
  },
  searchBar: {
    transition: 'width 0.3s ease-in-out',
  },
}));

interface NavSearchProps {
  showSearch: boolean;
  setShowSearch: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * UDSNavSearch component provides a search interface in the navigation bar.
 */
export const UDSNavSearch: React.FC<NavSearchProps> = ({
  showSearch,
  setShowSearch,
}) => {
  const [query, setQuery] = useState('');
  const searchRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const navigate = useNavigate();

  // Only update the query state without triggering a search
  const handleInputChange = (value: string) => {
    setQuery(value);
  };

  // Trigger search only when the form is submitted
  const handleSubmit = () => {
    if (query.trim()) {
      navigate(`/search?query=${encodeURIComponent(query.trim())}`);
    }
    setQuery('');
    setShowSearch(false);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setQuery('');
        setShowSearch(false);
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowSearch]);

  return (
    <div className={classes.searchContainer} ref={searchRef}>
      {showSearch && (
        <div className={classes.searchBar}>
          <form onSubmit={handleSubmit}>
            <Search
              initialValue={query}
              searchIconPosition="right"
              onChange={handleInputChange}
              onSubmit={handleSubmit}
              tokens={{ clearButtonIcon: null, submitButtonIcon: Close }}
            />
          </form>
        </div>
      )}
      {!showSearch && (
        <IconButton icon={SearchIcon} onPress={() => setShowSearch(true)} />
      )}
    </div>
  );
};
