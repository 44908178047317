import mfeLite from './components/mfe-lite';
import wask from './components/wask';

type MfeConfig = {
  [key: string]: {
    remote: string,
    config: {
      name: string
      remotes: any
      shared?: any
    }
  }
};

export const mfeConfig: MfeConfig = {
  mfeLite,
  wask
};
