import React, { useState } from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import MailIcon from '@material-ui/icons/Mail';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import { Link } from '@backstage/core-components';
import { UserEntity } from '@backstage/catalog-model';
import { Avatar } from '@backstage/core-components';
import { EntityUserDialog } from '../EntityEditor/EntityUserDialog';

export const CardTitle = ({ title }: { title: string | undefined }) => (
  <Box display="flex" flexDirection="row">
    <Typography
      variant="h5"
      style={{ paddingTop: '0.2rem', paddingRight: '0.5rem' }}
    >
      <PersonIcon />
    </Typography>
    <Typography variant="h5">{title}</Typography>
  </Box>
);

export const EntityUserProfileCard = () => {
  const { entity } = useEntity<UserEntity>();
  const [editorOpenClose, setEditorOpenClose] = useState(false);

  return (
    <Box>
      <EntityUserDialog
        open={editorOpenClose}
        onClose={() => {
          setEditorOpenClose(false);
        }}
      />
      <Card>
        <CardHeader
          title={<CardTitle title={entity?.spec?.profile?.displayName} />}
          action={
            <IconButton
              onClick={() => {
                setEditorOpenClose(true);
              }}
            >
              <EditIcon />
            </IconButton>
          }
          subheader={undefined}
        />
        <CardContent>
          <Box display="flex" flexDirection="row">
            <Box paddingRight="2rem">
              <Avatar displayName={entity?.spec?.profile?.displayName} />
            </Box>
            <Box display="flex" flexDirection="column">
              <Box display="flex" flexDirection="row">
                <Typography variant="h4" style={{ paddingRight: '1rem' }}>
                  <MailIcon />
                </Typography>
                <Typography variant="h4">
                  <Link to={`mailto:${entity?.spec?.profile?.email}`}>
                    {entity?.spec?.profile?.email}
                  </Link>
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row">
                <Typography variant="h4" style={{ paddingRight: '1rem' }}>
                  <PeopleIcon />
                </Typography>
                <Typography variant="h4">
                  {entity?.relations?.map((item: any, index: number) => {
                    const [kind, namespace, name] = item?.targetRef?.split(/[:\/]/);
                    if(!kind || !namespace || !name) return null
                    return (<React.Fragment key={index}>
                      {index > 0 && ', '}
                      <Link
                        to={`/catalog/${namespace}/${kind}/${name}`}
                      >
                        {name}
                      </Link>
                    </React.Fragment>);
                  }
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
