import { Content, Page } from '@backstage/core-components';
import { Grid } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import Typography from '@material-ui/core/Typography';
import { InfoCard } from '@backstage/core-components';
import React,  {FC} from 'react';


type ExternalLinkListItem = {
    icon: FC;
    url: string;
    title: string;
    description: string;
  }

export const ExternalLinksPage = () => {

    const brc = 'https://telus.com/en/brand-resources'
    const ep = 'https://sites.google.com/telus.com/engineering-productivity/home'
    const ccoe = 'https://sites.google.com/telus.com/cloudcoe/support/the-team'
    const acop = 'https://sites.google.com/telus.com/architecture-cop/meet-the-team'
    const tsee = 'https://sites.google.com/telus.com/tsee/home'
    const trm = 'https://sites.google.com/telus.com/rcop/home-jk'
    const dcp = 'https://sites.google.com/telus.com/digitalcareerframework/home'

    const externalLinksListItems =
    [
        { icon: InsertLinkIcon, url: brc, title:'Brand Resource Centre' ,description: 'The central hub for all brand documentation' },
        { icon: InsertLinkIcon, url: ep, title:'Engineering Productivity' ,description: 'Engineering Productivity Google site' },
        { icon: InsertLinkIcon, url: ccoe, title:'Cloud Centre of Excellence' ,description: 'Cloud Centre of Excellence Google site' },
        { icon: InsertLinkIcon, url: acop, title:'Architecture CoP' ,description: 'Architecture CoP Google site' },
        { icon: InsertLinkIcon, url: tsee, title:'TELUS Sales Engineering Excellence' ,description: 'TSEE Quality Hub: Your go-to for quality engineering' },
        { icon: InsertLinkIcon, url: trm, title: 'Telus Release Management' ,description: 'Telus Release Management Community of Practice' },
        { icon: InsertLinkIcon, url: dcp, title: 'Digital Career Framework' ,description: 'Digital Career Framework Google site' },
    ]

    const generateList = (arr: Array<ExternalLinkListItem>) => {
        return (<List>
        {
            arr.map((item, index) => {
            const Icon = item.icon
            return (
                <ListItem key={`welcome-item-${index}`}>
                <ListItemIcon>
                    <Icon />
                </ListItemIcon>
                <ListItemText
                    primary={<InfoCard><Link href={item.url} underline='always'>{item.title} </Link><Typography>{item.description}</Typography></InfoCard>}
                />
                </ListItem>
            )
            })}
        </List>)
    }

    return (
        <Page themeId="home">
            <Content>
                <InfoCard titleTypographyProps={{ variant: "h2"}} title="Useful External Links" >
                    <Grid container item xs={12} >
                        {generateList(externalLinksListItems)}
                    </Grid>
                </InfoCard>
            </Content>
        </Page>
    );
};
