/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React, { ReactNode, useMemo, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import {
  EntityApiDefinitionCard,
  EntityConsumedApisCard,
  EntityConsumingComponentsCard,
  EntityHasApisCard,
  EntityProvidedApisCard,
  EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import {
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityHasSubcomponentsCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntityLinksCard,
  EntitySwitch,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  isComponentType,
  isKind,
  hasCatalogProcessingErrors,
  isOrphan,
  hasRelationWarnings,
  EntityRelationWarning,
} from '@backstage/plugin-catalog';
// import {
//   isGithubActionsAvailable,
//   EntityGithubActionsContent,
// } from '@backstage/plugin-github-actions';
import {
  EntityMembersListCard,
  EntityOwnershipCard,
} from '@backstage/plugin-org';
import { EmptyState } from '@backstage/core-components';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';
// import { EntityKubernetesContent, isKubernetesAvailable } from '@backstage/plugin-kubernetes'; // Disabled not being used or needed
import {
  isPluginApplicableToEntity as isPagerDutyAvailable,
  EntityPagerDutyCard,
} from '@backstage/plugin-pagerduty';
import {
  EntitySecurityInsightsContent,
  SecurityInsightsWidget,
  DependabotAlertsWidget,
  isSecurityInsightsAvailable,
} from '@telus/plugin-security-insights';

import { DynatraceTab, isDynatraceAvailable } from '@telus/plugin-dynatrace';

import {
  EntityJiraOverviewCard,
  isJiraAvailable,
} from '@roadiehq/backstage-plugin-jira';

import { EntityBadgesDialog } from '@backstage-community/plugin-badges';
import BadgeIcon from '@material-ui/icons/CallToAction';

import {
  GoogleCloudDeployPage,
  isGoogleCloudDeployAvailable,
} from '@telus/plugin-google-cloud-deploy';


const EntityLayoutWrapper = (props: { children?: ReactNode }) => {
  const [badgesDialogOpen, setBadgesDialogOpen] = useState(false);

  const extraMenuItems = useMemo(() => {
    return [
      {
        title: 'Badges',
        Icon: BadgeIcon,
        onClick: () => setBadgesDialogOpen(true),
      },
    ];
  }, []);

  return (
    <>
      <EntityLayout UNSTABLE_extraContextMenuItems={extraMenuItems}>
        {props.children}
      </EntityLayout>
      <EntityBadgesDialog
        open={badgesDialogOpen}
        onClose={() => setBadgesDialogOpen(false)}
      />
    </>
  );
};

import {
  EntityGithubPullRequestsContent,
  EntityGithubPullRequestsOverviewCard,
} from '@roadiehq/backstage-plugin-github-pull-requests';
import { RiskEngineAssessmentsPage } from '@telus/plugin-risk-engine-assessments';

import {
  telusCustomAdrFilterFn,
  telusCustomContentTransformation,
} from '@telus/frontend-common';
import {
  EntityAdrContent,
  isAdrAvailable,
  AdrReader,
  AdrContentDecorator,
} from '@backstage/plugin-adr';

const telusAdrDecorator: AdrContentDecorator = ({ content }) => {
  return { content: telusCustomContentTransformation(content) };
};

import { DocsViewerEntityDocumentPage } from '@telus/plugin-docs-viewer-frontend';
import { EntityAboutCard } from './EntityAboutCard';
import { EntityUserProfileCard } from './EntityUserProfileCard';
import { EntityGroupProfileCard } from './EntityGroupProfileCard';

const entityDocsViewerTab = <DocsViewerEntityDocumentPage />;

const cicdContent = (
  // This is an example of how you can implement your company's logic in entity page.
  // You can for example enforce that all components of type 'service' should use GitHubActions
  <EntitySwitch>
    {/*
      Here you can add support for different CI/CD services, for example
      using @backstage-community/plugin-github-actions as follows:
      <EntitySwitch.Case if={isGithubActionsAvailable}>
        <EntityGithubActionsContent />
      </EntitySwitch.Case>
    */}

    <EntitySwitch.Case>
      <EmptyState
        title="No CI/CD available for this entity"
        missing="info"
        description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
          >
            Read more
          </Button>
        }
      />
    </EntitySwitch.Case>
  </EntitySwitch>
);

const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasRelationWarnings}>
        <Grid item xs={12}>
          <EntityRelationWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

const overviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}
    {/* Left Column */}
    <Grid container item md={6}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <EntityAboutCard />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <EntityGithubPullRequestsOverviewCard variant="gridItem" />
      </Grid>
      <EntitySwitch>
        <EntitySwitch.Case if={isSecurityInsightsAvailable}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <DependabotAlertsWidget />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <EntityHasSubcomponentsCard variant="gridItem" />
      </Grid>
      <EntitySwitch>
        <EntitySwitch.Case if={isPagerDutyAvailable}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <EntityPagerDutyCard />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
    </Grid>
    {/* Right Column */}
    <Grid container item md={6}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <EntityCatalogGraphCard variant="gridItem" height={300} />
      </Grid>
      <EntitySwitch>
        <EntitySwitch.Case if={isSecurityInsightsAvailable}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <SecurityInsightsWidget />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
      <EntitySwitch>
        <EntitySwitch.Case if={isJiraAvailable}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <EntityJiraOverviewCard />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <EntityLinksCard />
      </Grid>
    </Grid>
  </Grid>
);

const serviceEntityPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/google-cloud-deploy"
      title="Google Cloud Deploy"
      if={isGoogleCloudDeployAvailable}
    >
      <GoogleCloudDeployPage />
    </EntityLayout.Route>

    <EntityLayout.Route path="/pull-requests" title="Pull Requests">
      <EntityGithubPullRequestsContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/api" title="API">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item md={6}>
          <EntityConsumedApisCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {entityDocsViewerTab}
    </EntityLayout.Route>

    {/* <EntityLayout.Route path="/kubernetes" title="Kubernetes" if={isKubernetesAvailable}>
      <EntityKubernetesContent />
    </EntityLayout.Route> */}

    <EntityLayout.Route path="/security-insights" title="Security Insights">
      <EntitySecurityInsightsContent />
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/dynatrace"
      title="Dynatrace"
      if={isDynatraceAvailable}
    >
      <DynatraceTab />
    </EntityLayout.Route>
    <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
      <EntityAdrContent
        filePathFilterFn={telusCustomAdrFilterFn}
        contentDecorators={[
          AdrReader.decorators.createRewriteRelativeLinksDecorator(),
          AdrReader.decorators.createRewriteRelativeEmbedsDecorator(),

          telusAdrDecorator,
        ]}
      />
    </EntityLayout.Route>

    <EntityLayout.Route path="/assessments" title="assessments">
      <RiskEngineAssessmentsPage />
    </EntityLayout.Route>

  </EntityLayoutWrapper>
);

const websiteEntityPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/google-cloud-deploy"
      title="Google Cloud Deploy"
      if={isGoogleCloudDeployAvailable}
    >
      <GoogleCloudDeployPage />
    </EntityLayout.Route>

    {/* <EntityLayout.Route
      path="/kubernetes"
      title="Kubernetes"
      if={isKubernetesAvailable}
    >
      <EntityKubernetesContent />
    </EntityLayout.Route> */}

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {entityDocsViewerTab}
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/dynatrace"
      title="Dynatrace"
      if={isDynatraceAvailable}
    >
      <DynatraceTab />
    </EntityLayout.Route>

    <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
      <EntityAdrContent
        filePathFilterFn={telusCustomAdrFilterFn}
        contentDecorators={[
          AdrReader.decorators.createRewriteRelativeLinksDecorator(),
          AdrReader.decorators.createRewriteRelativeEmbedsDecorator(),
          telusAdrDecorator,
        ]}
      />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */

const defaultEntityPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {entityDocsViewerTab}
    </EntityLayout.Route>
    <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
      <EntityAdrContent
        filePathFilterFn={telusCustomAdrFilterFn}
        contentDecorators={[
          AdrReader.decorators.createRewriteRelativeLinksDecorator(),
          AdrReader.decorators.createRewriteRelativeEmbedsDecorator(),
          telusAdrDecorator,
        ]}
      />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      {serviceEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      {websiteEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);

const apiPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={4} xs={12}>
          <EntityLinksCard />
        </Grid>
        <Grid container item md={12}>
          <Grid item md={6}>
            <EntityProvidingComponentsCard />
          </Grid>
          <Grid item md={6}>
            <EntityConsumingComponentsCard />
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
      <EntityAdrContent
        filePathFilterFn={telusCustomAdrFilterFn}
        contentDecorators={[
          AdrReader.decorators.createRewriteRelativeLinksDecorator(),
          AdrReader.decorators.createRewriteRelativeEmbedsDecorator(),
          telusAdrDecorator,
        ]}
      />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const userPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid data-di-mask item xs={12} md={6}>
          <EntityUserProfileCard />
        </Grid>
        <Grid data-di-mask item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const groupPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid data-di-mask item xs={12} md={6}>
          <EntityGroupProfileCard />
        </Grid>
        <Grid data-di-mask item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
        <Grid data-di-mask item xs={12}>
          <EntityMembersListCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const systemPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6}>
          <EntityHasComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasApisCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.TOP_BOTTOM}
        title="System Diagram"
        height={700}
        relations={[
          RELATION_PART_OF,
          RELATION_HAS_PART,
          RELATION_API_CONSUMED_BY,
          RELATION_API_PROVIDED_BY,
          RELATION_CONSUMES_API,
          RELATION_PROVIDES_API,
          RELATION_DEPENDENCY_OF,
          RELATION_DEPENDS_ON,
        ]}
        unidirectional={false}
      />
    </EntityLayout.Route>

    <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
      <EntityAdrContent
        filePathFilterFn={telusCustomAdrFilterFn}
        contentDecorators={[
          AdrReader.decorators.createRewriteRelativeLinksDecorator(),
          AdrReader.decorators.createRewriteRelativeEmbedsDecorator(),
          telusAdrDecorator,
        ]}
      />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const domainPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
      <EntityAdrContent
        filePathFilterFn={telusCustomAdrFilterFn}
        contentDecorators={[
          AdrReader.decorators.createRewriteRelativeLinksDecorator(),
          AdrReader.decorators.createRewriteRelativeEmbedsDecorator(),
          telusAdrDecorator,
        ]}
      />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
