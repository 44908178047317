import React, { useState } from 'react';
import { Link, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import simplifyLogo from './simplifyLogo.png';
import { Settings } from '@telus-uds/palette-allium/build/web/icons';
import { IconButton } from '@telus-uds/components-web';
import { useHotkeys } from 'react-hotkeys-hook';
import { DropdownMenu } from '../old-nav/DropdownMenu';
import { UDSNavSearch } from './UDSNavSearch';
import { UDSNavCommandSearch } from './CommandSearch/UDSCommandSearch';
import { collapseItems } from './helpers/CollapseItems';
import { CustomNavigationBar } from './CustomNavigationBar/CustomNavigationBar';
import { NavTab } from './types';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  // Logo section
  leftSection: {
    width: '240px',
  },
  logo: {
    width: '100%',
    height: 'auto',
    alignSelf: 'center',
  },
  // Nav Bar section
  centerSection: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    height: '80px',
    alignItems: 'center',
  },
  navContainer: {
    position: 'relative',
  },
  // Collapsed menu specific styles (for medium and search)
  collapsedMenu: {
    // Target the collapsed menu button
    '& .NavigationItem__ItemContainer-components-web__sc-ql9x7c-0 button': {
      width: '290px !important',
    },

    // Target the button's inner container to ensure proper sizing
    '& .NavigationItem__ItemContainer-components-web__sc-ql9x7c-0 button > div':
      {
        minWidth: '290px !important',
      },

    // Target the dropdown menu container
    '& .NavigationDropdown__Container-components-web__sc-1472g3a-0': {
      minWidth: '290px !important',
      width: '290px !important',
    },

    // Target the dropdown menu items for consistent width
    '& .NavigationItem__ItemContainer-components-web__sc-ql9x7c-0': {
      minWidth: '290px !important',
      width: '290px !important',
    },

    // Target the dropdown list element
    '& ul.NavigationDropdown__Dropdown-components-web__sc-1472g3a-1': {
      minWidth: '290px !important',
      width: '290px !important',
    },

    // Target individual dropdown items
    '& .NavigationDropdown__DropdownItem-components-web__sc-1472g3a-2': {
      minWidth: '270px !important',
      width: '270px !important',
    },
  },
  // Button section
  rightSection: {
    width: '240px', // Match the logo width for balance
    display: 'flex',
    justifyContent: 'flex-end', // Align buttons to the right
  },
  buttonBar: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginRight: '10px',
  },
}));

interface UDSNavProps {
  navItems: NavTab[];
}

export const UDSNav = ({ navItems }: UDSNavProps) => {
  const [selected, setSelected] = useState<string>('home');
  const [showSearch, setShowSearch] = useState(false);
  const [showCommandSearch, setShowCommandSearch] = useState(false);
  const classes = useStyles();
  const useMobile = useMediaQuery('(max-width: 1430px)');

  useHotkeys('ctrl+k, cmd+k', event => {
    event.preventDefault();
    setShowCommandSearch(true);
  });

  return (
    <div className={classes.root}>
      <div className={classes.leftSection}>
        <Link component="a" href="/">
          <img
            className={classes.logo}
            src={simplifyLogo}
            alt="TELUS | Simplify Hub"
          />
        </Link>
      </div>
      <div className={classes.centerSection}>
        <div
          className={`${classes.navContainer} ${
            useMobile ? classes.collapsedMenu : ''
          }`}
        >
          <CustomNavigationBar
            onChange={(id: string) => setSelected(id)}
            selectedId={selected}
            items={
              useMobile
                ? collapseItems({
                    items: navItems,
                    selectedId: selected,
                  })
                : navItems
            }
          />
        </div>
      </div>
      <div className={classes.rightSection}>
        <div className={classes.buttonBar}>
          <div>
            <DropdownMenu />
          </div>
          <div>
            <UDSNavSearch
              showSearch={showSearch}
              setShowSearch={setShowSearch}
            />
          </div>
          <div>
            <IconButton href="/settings" icon={Settings} />
          </div>
        </div>
        <UDSNavCommandSearch
          navItems={navItems}
          isOpen={showCommandSearch}
          onClose={() => setShowCommandSearch(false)}
        />
      </div>
    </div>
  );
};
