import {
  BackstageIdentityResponse,
  configApiRef,
  SignInPageProps,
  useApi,
} from '@backstage/core-plugin-api';
import { UserIdentity } from '@backstage/core-components';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import { makeStyles } from '@material-ui/styles';
import React, { useState, useEffect } from 'react';
import { useMountEffect } from '@react-hookz/web';
import { Progress, Content, Header, Page } from '@backstage/core-components';
import { GridItem } from './providers/styles';
import {
  SignInProviderConfig,
  IdentityProviders,
} from '@backstage/core-components';
import { getSignInProviders, useSignInProviders } from './providers/providers';
import { InfoCard } from '@backstage/core-components';
import { makeStyles } from '@material-ui/core';
import { t } from './translations';
import { useSearchParams } from 'react-router-dom';
import { DropdownMenu } from '../Root/old-nav/DropdownMenu';

type MultiSignInPageProps = SignInPageProps & {
  providers: IdentityProviders;
  title?: string;
  align?: 'center' | 'left';
};

type SingleSignInPageProps = SignInPageProps & {
  provider: SignInProviderConfig;
  auto?: boolean;
};

const useStyles = makeStyles(theme => ({
  contentStyles: {
    color: theme.palette.secondary.main,
  },
  gridStyles: {},
  providerStyles: {
    paddingInlineEnd: '5px',
    paddingInlineStart: '5px',
  },
  title: {
    fontSize: '40px',
    fontWeight: 325,
  },
}));

export type Props = MultiSignInPageProps | SingleSignInPageProps;

export const MultiSignInPage = ({
  onSignInSuccess,
  providers = [],
}: MultiSignInPageProps) => {
  const configApi = useApi(configApiRef);

  const classes = useStyles();

  const signInProviders = getSignInProviders(providers);
  const [loading, providerElements] = useSignInProviders(
    signInProviders,
    onSignInSuccess,
  );

  const [lang, setLang] = useState('');
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.get('lang')) {
      setLang('en');
    }
    if (searchParams.get('lang') === 'fr') {
      setLang('fr');
    }
    if (searchParams.get('lang') === 'en') {
      setLang('en');
    }
  }, [searchParams]);
  if (loading) {
    return <Progress />;
  }

  return (
    <Page themeId="home">
      <Header title={configApi.getString('app.title')}>
        <nav
          style={{
            padding: '7px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <DropdownMenu />
        </nav>
      </Header>
      <Content className={classes.contentStyles}>
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          className={classes.gridStyles}
        >
          <Grid item>
            <Typography
              className={classes.title}
              style={{ padding: '0 5px 32px 5px', justifySelf: 'center' }}
              variant="h3"
            >
              {t.title[lang]}
            </Typography>
          </Grid>
          <Grid
            container
            spacing={2}
            component="ul"
            className={classes.providerStyles}
            justifyContent="center"
          >
            {providerElements}
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};

export const SingleSignInPage = ({
  provider,
  auto,
  onSignInSuccess,
}: SingleSignInPageProps) => {
  const classes = useStyles();
  const authApi = useApi(provider.apiRef);
  const configApi = useApi(configApiRef);

  const [error, setError] = useState<Error>();
  const [lang, setLang] = useState('');
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.get('lang')) {
      setLang('en');
    }
    if (searchParams.get('lang') === 'fr') {
      setLang('fr');
    }
    if (searchParams.get('lang') === 'en') {
      setLang('en');
    }
  }, [searchParams]);

  // The SignIn component takes some time to decide whether the user is logged-in or not.
  // showLoginPage is used to prevent a glitch-like experience where the sign-in page is
  // displayed for a split second when the user is already logged-in.
  const [showLoginPage, setShowLoginPage] = useState<boolean>(false);

  type LoginOpts = { checkExisting?: boolean; showPopup?: boolean };
  const login = async ({ checkExisting, showPopup }: LoginOpts) => {
    try {
      let identityResponse: BackstageIdentityResponse | undefined;
      if (checkExisting) {
        // Do an initial check if any logged-in session exists
        identityResponse = await authApi.getBackstageIdentity({
          optional: true,
        });
      }

      // If no session exists, show the sign-in page
      if (!identityResponse && (showPopup || auto)) {
        // Unless auto is set to true, this step should not happen.
        // When user intentionally clicks the Sign In button, autoShowPopup is set to true
        setShowLoginPage(true);
        identityResponse = await authApi.getBackstageIdentity({
          instantPopup: true,
        });
        if (!identityResponse) {
          throw new Error(
            `The ${provider.title} provider is not configured to support sign-in`,
          );
        }
      }

      if (!identityResponse) {
        setShowLoginPage(true);
        return;
      }

      const profile = await authApi.getProfile();
      onSignInSuccess(
        UserIdentity.create({
          identity: identityResponse.identity,
          authApi,
          profile,
        }),
      );
    } catch (err: any) {
      // User closed the sign-in modal
      setError(err);
      setShowLoginPage(true);
    }
  };

  useMountEffect(() => login({ checkExisting: true }));

  return showLoginPage ? (
    <Page themeId="home">
      <Header title={configApi.getString('app.title')} />
      <Content>
        <Grid
          container
          justifyContent="center"
          spacing={2}
          component="ul"
          className={classes.gridStyles}
        >
          <GridItem>
            <InfoCard
              variant="fullHeight"
              title={t[provider.title][lang]}
              actions={
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    login({ showPopup: true });
                  }}
                >
                  Sign In
                </Button>
              }
            >
              <Typography variant="body1">
                {t[provider.message][lang]}
              </Typography>
              {error && error.name !== 'PopupRejectedError' && (
                <Typography variant="body1" color="error">
                  {error.message}
                </Typography>
              )}
            </InfoCard>
          </GridItem>
        </Grid>
      </Content>
    </Page>
  ) : (
    <Progress />
  );
};

export function SignInPage(props: Props) {
  if ('provider' in props) {
    return <SingleSignInPage {...props} />;
  }

  return <MultiSignInPage {...props} />;
}
